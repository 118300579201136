import $ from 'jquery';

import { enableScroll, disableScroll } from '../utilities';

$(() => {

	// Toggle mobile menu visibility when button clicked
	$('#js-mobile-menu-button').click(event => {
		const $mobileMenuButton = $(event.currentTarget);
		const $mobileMenu = $('#js-mobile-menu');

		if($mobileMenu.hasClass('is-visible')) {
			$mobileMenu.removeClass('is-visible');
			$mobileMenuButton.removeClass('is-menu-visible');
			enableScroll();
		}
		else {
			$mobileMenu.addClass('is-visible');
			$mobileMenuButton.addClass('is-menu-visible');
			disableScroll();
		}
	});

	// Toggle dropdowns in mobile menu
	$('.js-mobile-menu-dropdown-button').click(event => {
		const $button = $(event.currentTarget);

		$button.toggleClass('is-open');
		$button.siblings('.js-mobile-menu-dropdown').slideToggle(300);
	});

});
